import { Link } from "react-router-dom";
import "./Header.css";
export default function Header({ isLogoVisible }) {
  return (
    <header
      className="header"
      style={
        isLogoVisible
          ? {
              backgroundColor: "#fff",
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
            }
          : { backgroundColor: "#fff", justifyContent: "space-evenly" }
      }
    >
      <Link className="link" to={"/"}>
        <h1
          className="logo"
          style={isLogoVisible ? { width: `15vw` } : { width: 0 }}
        ></h1>
      </Link>

      <nav
        style={
          !isLogoVisible
            ? { width: "80vw", justifyContent: "space-between" }
            : { width: "70vw", justifyContent: "space-evenly" }
        }
      >
        <Link className="nav-option link" to={"/"}>
          <a>Home</a>
        </Link>
        <Link className="nav-option link" to={"./#about"}>
          <a href="">About Us</a>
        </Link>
        <a
          className="nav-option link"
          href={"https://lumona.electrixitaty.co"}
          target={"_blank"}
          rel="norefere"
        >
          <a>The Founder</a>
        </a>
        <Link className="nav-option link" to={"./#projects"}>
          <a href="">Projects</a>
        </Link>

        <Link className="nav-option link" to={"./#contact"}>
          <a href="">Get In Touch</a>
        </Link>
      </nav>
    </header>
  );
}
