import "./About.css";

export default function About() {
  return (
    <section className="about" id="about">
      <div className="container">
        <div className="content">
          <div className="section-title">
            <h2>About Electrixitaty</h2>
          </div>
          <p>
            At Electrixitaty, we are passionate about driving technological
            advancements and innovation in Kenya. Our journey began with a
            vision to empower individuals and communities through technology.
            Since then, we have initiated various projects and established
            numerous businesses under our umbrella, each dedicated to addressing
            pressing issues and driving positive change.
          </p>
        </div>
      </div>
    </section>
  );
}
