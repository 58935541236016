import { useState } from "react";
import Header from "../../components/Header";
import Hero from "./sections/hero/Hero";
import "./Home.css";
import About from "./sections/about/About";
import Projects from "./sections/projects/Projects";
import Contact from "./sections/contact/Contact";

export default function Home({ isNotHomePage }) {
  const [isLogoVisible, setIsLogoVisible] = useState(false);

  const [heroTitleTop, setHeroTitleTop] = useState(50);
  const [heroTitleLeft, setHeroTitleLeft] = useState(50);
  const [heroTitleSize, setHeroTitleSize] = useState(500);
  const [heroTransformX, setTransformX] = useState(-50);
  const [heroTransformY, setTransformY] = useState(-50);

  const [isIntroInfo, setIsIntroInfo] = useState(false);
  // if (!isNotHomePage) {
  window.addEventListener("scroll", () => {
    if (
      document.getElementById("hero")?.getBoundingClientRect()?.bottom < 780
    ) {
      setHeroTitleTop(0);
      setHeroTitleLeft(0);
      setHeroTitleSize(128);
      setTransformX(5);
      setTransformY(5);
      setIsLogoVisible(true);
    } else {
      setHeroTitleTop(50);
      setHeroTitleLeft(50);
      setHeroTitleSize(500);
      setTransformX(-50);
      setTransformY(-50);
      setIsLogoVisible(false);
    }
  });
  window.addEventListener("scroll", () => {
    if (
      document.getElementById("hero")?.getBoundingClientRect()?.bottom < 760
    ) {
      setIsIntroInfo(true);
    } else {
      setIsIntroInfo(false);
    }
  });
  // }

  return (
    <main>
      <Header isLogoVisible={isLogoVisible} />
      <Hero
        isLogoVisible={isLogoVisible}
        heroTitleTop={heroTitleTop}
        heroTitleLeft={heroTitleLeft}
        heroTitleSize={heroTitleSize}
        heroTransformX={heroTransformX}
        heroTransformY={heroTransformY}
        isIntroInfo={isIntroInfo}
      />
      <About />
      <Projects />
      <Contact />
      {/* <Footer /> */}
    </main>
  );
}
