import "./Footer.css";

export default function Footer() {
  return (
    <footer id="footer" className="footer position-relative">
      <div className="container copyright text-center ">
        <p>
          © <span>Copyright</span>{" "}
          <strong className="px-1">Electrixitaty</strong>{" "}
          <span>All Rights Reserved</span>
        </p>
        <div className="credits">
          Designed by <a href="https://electrixitaty.com/">Lumona Mulengwa</a>
        </div>
      </div>
    </footer>
  );
}
